interface ImagemProps{

  imagem: string
  descricao: string

}

export function Imagem({imagem, descricao}: ImagemProps){

  return(

    <div className="col-lg-6 text-center mb-3">

      <img src={imagem} alt={descricao} className="img-fluid" />

    </div>

  )

}
