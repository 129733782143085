import styled from "styled-components";

export const Container = styled.div`

  background-color: ${({theme})=>theme.colors.black};
  text-decoration: none;

  img{

    object-fit: contain;
    width: 100%;
    height: 20rem;

  }

  p{
    color: ${({theme})=>theme.colors.lightGray};
    font-size: 1rem;
    margin-top: .8rem;
  }

  h2{
    font-size: 1.6rem;
    color: ${({theme})=>theme.colors.white};
  }

  .btnMaisInfo{
    color: ${({theme})=>theme.colors.white};
    font-weight: 700;
    border-radius: 20px;

    &:active{

      color: ${({theme})=>theme.colors.default};
      border: 1px solid ${({theme})=>theme.colors.default};

    }

  }

  @media screen and (max-width: ${({theme})=>theme.breakPoints.md}) {

    img{
      height: 13rem;
    }

    h2{
      font-size: 1.4rem;
    }

  }

  @media screen and (max-width: ${({theme})=>theme.breakPoints.sm}) {

    img{
      height: 10rem;
    }

    h2{
      font-size: 1.2rem;
    }

    .btnMaisInfo{
      display: none;
    }

  }

`;

export const Price = styled.span`

  font-size: 1.5rem;
  /* color: ${({theme})=>theme.colors.white}; */
  color: #84e509;
  font-weight: 600;
  display: block;

  @media screen and (max-width: ${({theme})=>theme.breakPoints.sm}) {
    font-size: 1.2rem;
    margin-top: 1rem;
  }

  @media screen and (max-width: ${({theme})=>theme.breakPoints.md}) {
    font-size: 1.4rem;
  }

`;

export const BtnComprarWts = styled.a`

  background-color: ${({theme})=>theme.colors.default};
  color: ${({theme})=>theme.colors.white};
  border-radius: 20px;

  &:hover{

    background-color: ${({theme})=>theme.colors.defaultHover};
    color: ${({theme})=>theme.colors.white};
  }


  @media screen and (max-width: ${({theme})=>theme.breakPoints.sm}) {
    font-size: 0.9rem;
  }

`;
