import { Spiner } from "./styles"

export function Loader(){

  return(

    <Spiner></Spiner>

  )

}
