import styled from "styled-components";

export const Loader = styled.div`

  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid ${({theme})=>theme.colors.black};
  border-radius: 5px;
  padding: 1rem;
  background-color: ${({theme})=>theme.colors.darkGray};
  overflow: hidden;

  &::after{

    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(110deg, rgba(227, 227, 227, 0) 0%, rgba(227, 227, 227, 0) 40%, ${({theme})=>theme.colors.darkGray} 50%, rgba(227, 227, 227, 0) 60%, rgba(227, 227, 227, 0) 100%);
    animation: gradient-animation_2 1.2s linear infinite;

  }

  .wrapper{

    width: 100%;
    height: 100%;
    position: relative;

    &>div{

      background-color: ${({theme})=>theme.colors.black};

    }

  }

  .produto {
    width: 100%;
    height: 200px;
    margin-bottom: .7rem;
  }

  .titulo {
    height: 35px;
    width: 80%;
    margin-bottom: .7rem;
  }

  .descricao {
    height: 15px;
    width: 100%;
    margin-bottom: .7rem;
  }

  .preco{
    width: 30%;
    height: 35px;
    margin-bottom: 1rem;
  }

@keyframes gradient-animation_2 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

`;
