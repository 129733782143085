import styled from "styled-components";

export const Spiner = styled.div`

  width: 74px;
  height: 50px;
  display: grid;
  animation: spinner 3s infinite;

  &::before,
  ::after {
    content: "";
    grid-area: 1/1;
    border: 9px solid;
    border-radius: 50%;
    border-color: ${({theme})=>theme.colors.default} ${({theme})=>theme.colors.default} #0000 #0000;
    mix-blend-mode: darken;
    animation: spinner 1s infinite linear;
  }

  &::after {
    border-color: #0000 #0000 #dbdcef #dbdcef;
    animation-direction: reverse;
  }

  @keyframes spinner {
    100% {
      transform: rotate(1turn);
    }
  }

`;
