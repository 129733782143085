import { NavBar, Logo, OffCanvaMenu } from "./styles";
import { Link } from "react-router-dom";

import { FaBars } from "react-icons/fa6";

import Borboleta from '../../img/logo_borboleta_rosa_transparente-min.png';


export function Header(){

  return(

    <NavBar className="navbar navbar-expand-md">
      <div className="container-fluid">
        <a className="navbar-brand" href="/"><Logo src={Borboleta} alt="Logo Sensualite" /></a>
        <button className="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
          <FaBars />
        </button>
        <OffCanvaMenu className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Menu</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/">Início</Link>
              </li>
            </ul>
          </div>
        </OffCanvaMenu>
      </div>
    </NavBar>

  )

}
