import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Item } from "../../../components/item";
//import { Loader } from '../../../components/Loader/loader';

import PreLoader from '../../../components/PreLoader';

import { buscaProdutos } from '../../../services/api';

export default function Produtos(){

  const { categoria } = useParams();
  const [listaProdutos, setListaProdutos] = useState({'produtos':[{'categoria':''}]});
  const [loading, setLoading] = useState(true);

  useEffect(()=>{

    (async ()=>{

      const produtosRequest = await buscaProdutos();

      if(produtosRequest.data.mensagem == "Lista recuperada com sucesso"){

        setListaProdutos(produtosRequest.data);
        setLoading(false);

      }else{

        alert("Não foi possivel recuperar lista de produtos");

      }

    })()

  }, []);


  if(loading){

    return(

      <div className="row">

        {[...Array(8)].map((_, index) => (
          <div key={index} className="col-6 col-md-4 col-xxl-3 mb-3">
            <PreLoader />
          </div>
        ))}


      </div>

    )

  }

  return(

    <section className="row">

      <Item produto={listaProdutos.produtos.filter((item)=> item.categoria.toLowerCase() === categoria || categoria === undefined)}/>

    </section>

  )

}
