import { NavLink } from "react-router-dom";

import { Nav } from "./styles";

interface Categoria {
  categoria_id: number;
  nome: string;
}

interface CategoriasProps {
  categorias: Categoria[]; // Atualizado para usar a nova interface
}

export function Categorias({categorias}: CategoriasProps){

  return(

      <Nav className="container-fluid mt-3 sticky-top">

        <ul>

          <li>
            <NavLink to='/'>Todos</NavLink>
          </li>

          {
              categorias.map((categoria)=>(

                <li key={categoria.categoria_id}>
                  <NavLink to={`/filtro/${categoria.nome.toLowerCase()}`}>{categoria.nome}</NavLink>
                </li>

              ))
          }

        </ul>

      </Nav>

  )

}
