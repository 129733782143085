import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

export const buscaCategorias = async () => api.get('/produtos/lista_categorias');

export const buscaBanners = async () => api.get('/banners');

export const buscaProdutos = async ()=> api.get('/produtos/lista_produtos');

export const buscaItem = async (key: string)=> api.get(`item?key=${key}`);

export default api
