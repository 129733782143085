import styled from "styled-components";

export const NavBar = styled.nav`

  background-color: ${({theme})=>theme.colors.black};

  a,
  h5{

    color: ${({theme})=>theme.colors.default};

    &:hover{

      color: ${({theme})=>theme.colors.defaultHover};

    }

  }

  svg{

    color: ${({theme})=>theme.colors.default};

  }

`;

export const Logo = styled.img`

  width: 50px;

`;

export const OffCanvaMenu = styled.div`

  background-color: ${({theme})=>theme.colors.black};

`;
