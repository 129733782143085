import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Container } from "./styles";

import { Header } from "../../components/header";
import { Imagem } from "./Imagem";
import { Informacoes } from "./Informacoes";
import { Loader } from "../../components/Loader/loader";

import { buscaItem } from "../../services/api";

export default function Produto(){

  const { idProduto } = useParams<{ idProduto: string }>();

  const [produto, setProduto] = useState({'produto':{'imagem':'', 'titulo':''}});
  const [loading, setLoading] = useState(true);

  useEffect(()=>{


    const loadData = async ()=> {

      const [responseProduto] = await Promise.all([

        buscaItem(idProduto || '')

      ]);

      setProduto(responseProduto.data);
      setLoading(false);

    }

    loadData();

  }, []);

  if(loading){

    return(

      <>

        <Header />

        <Container className="container my-3">

          <div className="row justify-content-center">

            <Loader />

          </div>

        </Container>

      </>

    )

  }

  return(

    <>

      <Header />

      <Container className="container my-3">

        <section className="row">

          <Imagem imagem={produto.produto.imagem} descricao={produto.produto.titulo} />

          <Informacoes produto={produto.produto} />

        </section>

      </Container>

    </>

  )

}
