import { Titulo, SubTitulo, BtnComprar, Descricao, Preco } from "./styles";

import { currencyFormat } from "../../../helpers/currencyFormat";

import { FaCartShopping } from "react-icons/fa6";

interface InformacoesProps{

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  produto: any

}

export function Informacoes({produto}: InformacoesProps){

  return(

      <div className="col-lg-6">

        <div className="d-flex flex-column gap-4 mb-3">
          <Titulo>{produto.titulo}</Titulo>

          <div className="d-flex flex-column gap-1">

            <SubTitulo>Descrição</SubTitulo>
            <Descricao>{produto.descricao}</Descricao>

          </div>

        </div>

        <div className="d-flex flex-row flex-xl-column justify-content-between align-items-start mb-4">

          <SubTitulo>Preço</SubTitulo>
          <Preco>{currencyFormat(produto.preco)}</Preco>

        </div>

        <div className="d-flex w-100">

          <BtnComprar className="btn w-100 rounded-5" href="https://wa.me/5511941741138"><FaCartShopping />&nbsp; Comprar</BtnComprar>

        </div>

      </div>


  )

}
