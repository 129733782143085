import styled from "styled-components";

export const Titulo = styled.h1`

  color: ${({theme})=>theme.colors.white};

`;

export const SubTitulo = styled.h2`

  color: ${({theme})=>theme.colors.white};
  font-size: ${({theme})=>theme.fontSize.xl};

`;

export const Descricao = styled.p`

  color: ${({theme})=>theme.colors.lightGray};

`;

export const Preco = styled.span`

  color: #84e509;
  font-size: ${({theme})=>theme.fontSize["2xl"]};
  font-weight: 600;

`;

export const BtnComprar = styled.a`

  background-color: ${({theme})=>theme.colors.default};
  color: ${({theme})=>theme.colors.white};
  transition: background .5s;

  &:hover{

    background-color: ${({theme})=>theme.colors.defaultHover};
    color: ${({theme})=>theme.colors.white};

  }

`;
