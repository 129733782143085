interface Banner {
  id_banner: number;
  link_promo: string;
  imagem_quadrada: string;
  imagem_horizontal: string;
  nome_promo: string;
}

interface bannersProps {
  banners: Banner[];
}

export default function Banner({banners}: bannersProps){

  return(

    <article className="container-fluid">

      <section className="row">

        <div className="col-12">

          <div id="bannerPromoCarousel" className="carousel slide" data-bs-ride="carousel">

            <div className="carousel-indicators">

              {

                banners.map((banner, index)=>(

                  <button key={banner.id_banner} type="button" data-bs-target="#bannerPromoCarousel" className={` ${index === 0 ? 'active' : ''}`} data-bs-slide-to={index} aria-label={`Slide`+index}></button>

                ))

              }

            </div>

            <div className="carousel-inner">

              {

                banners.map((banner, index) => (

                  <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={banner.id_banner} data-bs-interval="5000">

                    <a href={banner.link_promo}>

                      <picture>
                        <source media="(max-width: 576px)" srcSet={banner.imagem_quadrada} />
                        <source media="(min-width: 577px)" srcSet={banner.imagem_horizontal} />
                        <img className="d-block w-100" alt={banner.nome_promo} />
                      </picture>

                    </a>

                  </div>

                ))

              }

            </div>

            <button className="carousel-control-prev" type="button" data-bs-target="#bannerPromoCarousel" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Voltar</span>
            </button>

            <button className="carousel-control-next" type="button" data-bs-target="#bannerPromoCarousel" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Avançar</span>
            </button>

          </div>

        </div>

      </section>

    </article>

  )

}
