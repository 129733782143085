import {Loader} from './styles';

export default function PreLoader(){

  return(

    <Loader>
      <div className="wrapper">
        <div className="produto"></div>
        <div className="titulo"></div>
        <div className="descricao"></div>
        <div className="descricao"></div>
        <div className="descricao"></div>
        <div className="preco"></div>
      </div>
    </Loader>

  )

}
