import { Routes, Route } from 'react-router-dom';

import MainPage from './pages/Main';
import Produtos from './pages/Main/produtos';
import Produto from './pages/Produto';

export function AppRoutes() {
  return (
    <Routes>


      <Route path='/' element={<MainPage />}>

        <Route path='/' element={<Produtos />} />
        <Route path='/filtro/:categoria' element={<Produtos />} />

      </Route>

      <Route path='/produto/:idProduto' element={<Produto />} />


    </Routes>
  )
}
