import styled from "styled-components";

export const Nav = styled.nav`

  width: 100%;
  background-color: ${({theme})=>theme.colors.darkGray};

  ul{

    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    max-width: 100%;
    list-style: none;
    overflow-x: auto;
  }

  li{

    padding: 1rem .5rem;

    a{

      text-decoration: none;
      background-color: ${({theme})=>theme.colors.black};
      color: ${({theme})=>theme.colors.default};
      border: 1px solid ${({theme})=>theme.colors.black};
      padding: .5rem 2rem;
      border-radius: 999px;
      font-weight: 500;

      &.active{

        border: 1px solid ${({theme})=>theme.colors.default};
        color: ${({theme})=>theme.colors.default};

      }

    }

  }

`;
