/* eslint-disable @typescript-eslint/no-explicit-any */
import { Container, Price, BtnComprarWts } from "./styles";
import { FaCartShopping } from "react-icons/fa6";

import { Link } from "react-router-dom";
import { currencyFormat } from "../../helpers/currencyFormat";
import { truncateText } from "../../helpers/truncateText";

interface ItemProps{

  produto: any[]

}

export function Item({produto}: ItemProps){

  return(

    <>

      {

        produto.map((item)=>(

          <Link to={`/produto/${item.produto_hash}`} className="col-6 col-md-4 col-xxl-3 mb-3 text-decoration-none" key={item.produto_hash}>

            <Container className="shadow-sm h-100 rounded-3 pb-3 d-flex flex-column justify-content-between p-1 p-md-3 gap-3">

              <img src={item.imagem} alt={item.titulo} />

              <div>
                <h2>{item.titulo}</h2>
                <p className="d-none d-sm-block">{truncateText(item.descricao)}</p>
                <Price>{currencyFormat(item.preco)}</Price>
              </div>

              <div className="d-flex flex-column gap-2 gap-md-3">

                <BtnComprarWts href="https://wa.me/5511941741138" target="_blank" rel="noopener noreferrer" className="btn"><FaCartShopping size={18} /> Comprar</BtnComprarWts>

                <Link to={`/produto/${item.produto_hash}`} className="btn btnMaisInfo">Mais informações</Link>

              </div>

            </Container>

          </Link>

        ))

      }


    </>

  )

}
