import React, {useState, useEffect} from "react";
import { Outlet } from "react-router-dom";

import { Header } from "../../components/header";
import { Categorias } from "./categorias";
import Banner from "./banner";

import { buscaCategorias, buscaBanners } from "../../services/api";

interface Categoria {
  categoria_id: number;
  nome: string;
}

interface ListaCategorias {
  erro: boolean;
  mensagem: string;
  categorias: Categoria[];
}
interface Banners {
  id_banner: number;
  nome_promo: string;
  imagem_horizontal: string;
  imagem_quadrada: string;
  link_promo: string;
}

interface Banner{

erro: boolean
mensagem: string
banners?: Banners[]

}

export default function Main() {

  const [listaCategorias, setCategorias] = useState<ListaCategorias>({ erro: false, mensagem: "", categorias: [] });
  const [banners, setBanners] = useState<Banner>({ erro: false, mensagem: "" });

  useEffect(()=>{

    const loadData = async ()=>{

      const [categoriaResponse, bannersResponse] = await Promise.all([

        buscaCategorias(),
        buscaBanners()

      ]);

      //TRATANDO O RESPONDE DAS CATEGORIAS
      if(!categoriaResponse.data.erro){

        if(categoriaResponse.data.mensagem === "Categorias recuperadas com sucesso"){

          setCategorias(categoriaResponse.data);

        }else{

          console.log("Não foi encontrado nenhuma categoria cadastrada");

        }

      }else{

        console.log("Não foi possivel recuperar as categorias!");

      }

      //TRATANDO O RESPONSE DOS BANNERS
      if(!bannersResponse.data.erro){

        if(bannersResponse.data.mensagem === "Banner recuperado com sucesso"){

          setBanners(bannersResponse.data);

        }

      }else{

        console.log("Não foi possivel recuperar os banner");

      }

    }

    loadData();

  }, []);

  return (

    <>

      <Header />

      {banners.banners && <Banner banners={banners.banners} />}

      <Categorias categorias={listaCategorias.categorias} />

      <main className='container-fluid pt-5'>

        <Outlet />

      </main>

    </>

  )
}
